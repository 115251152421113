<template>
  <div>1</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    // console.log(sessionStorage.key(1));
  }
};
</script>